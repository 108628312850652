import React from "react"
import NavBar from "./nav-bar"
import "../stylesheets/components/layout.css"

const Layout = ({ children }) => (
  <>
    <NavBar />
    {children}
  </>
)
export default Layout
