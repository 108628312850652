import React from "react"
import { Link } from "gatsby"
import "../stylesheets/components/nav-page.css"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import WhiteLogo from "../../public/White Logo.png"
import backToTopImage from "../../public/up_arrow_shadow.svg"

export default class NavPage extends React.Component {

  render() {
    return (
      <div id="nav-page">

        <div className="pageLinks">
          <Link to="/" className="navLink">Home.</Link>
          <br/>
          <Link to="/services/" className="navLink">Services.</Link>
          <br/>
          <Link to="/our-work/" className="navLink">Our Work.</Link>
          <br/>
          <Link to="/about/" className="navLink">About.</Link>
          <br/>
          <Link to="/lets-talk/" className="navLink" style={{fontWeight: "bold",}}>Lets Talk.</Link>
        </div>

      </div>
    );
  }

}
