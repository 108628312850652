
import React from "react";
import '../stylesheets/components/nav-bar.css';
import {Link} from "gatsby";
import NavPage from "./nav-page.js";
import WhiteLogo from "../../public/White Logo.png"
import gsap from "gsap"

export default class NavigationBar extends React.Component {
  constructor(props) {
    super(props);

    this.isMobile = false;

    this.openNavPage = this.openNavPage.bind(this);
    this.navPageRef = null;
    const elem = (
      <div ref={div => this.navPageRef = div}><NavPage/></div>
    );

    this.hamburgerRefs = [null, null, null];

    this.state = {
      navpage: elem,
      navPageIsOpen: false,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);


    if (window.innerWidth < 1000) {
      this.isMobile = true;
      Array.from(document.getElementsByClassName('mob')).forEach((elem) => {
        elem.classList.add('Mobile');
      })
    }
    else if (window.innerWidth >= 1000) {
      this.isMobile = false;
      Array.from(document.getElementsByClassName('mob')).forEach((elem) => {
        elem.classList.remove('Mobile');
      })
    }
    this.setState({});

    window.addEventListener('resize', () => {
      if (window.innerWidth < 1000) {
        this.isMobile = true;
        Array.from(document.getElementsByClassName('mob')).forEach((elem) => {
          elem.classList.add('Mobile');
        })
      }
      else if (window.innerWidth >= 1000) {
        this.isMobile = false;
        Array.from(document.getElementsByClassName('mob')).forEach((elem) => {
          elem.classList.remove('Mobile');
        })
      }
      this.setState({});
    });
  }

  /* Fade In Navbar */
  handleScroll() {
    var navbar = document.getElementById("nav-bar");
    var navLogo = document.getElementById("navLogo");
    if (window.pageYOffset > 0) {
      navbar.classList.add("scrolled");
      navbar.classList.remove("unscrolled");
      navLogo.classList.add("scrolled");
      navLogo.classList.remove("unscrolled");
    } else if (window.pageYOffset === 0) {
      if (navbar.classList.contains("scrolled")) {
        navbar.classList.add("unscrolled");
        navbar.classList.remove("scrolled");
        navLogo.classList.add("unscrolled");
        navLogo.classList.remove("scrolled");
      }
    }
  }

  /* Opens a full page navigation for mobile */
  openNavPage() {
    let temp = !this.state.navPageIsOpen;
    this.setState({
      navPageIsOpen: temp,
    }, () => {
      if(temp) {
        gsap.from(this.navPageRef, 0.3, {opacity: 0});
        gsap.to(this.hamburgerRefs[0], 0.3, {rotation: 45, y: 15});
        gsap.to(this.hamburgerRefs[1], 0.3, {opacity: 0});
        gsap.to(this.hamburgerRefs[2], 0.3, {rotation: 135, y: -15});
      }
      else {
        gsap.to(this.hamburgerRefs[0], 0.3, {rotation: 0, y: 0});
        gsap.to(this.hamburgerRefs[1], 0.3, {opacity: 1});
        gsap.to(this.hamburgerRefs[2], 0.3, {rotation: 0, y: 0});
      }

    });



  }


  render() {
    if(this.isMobile) {
      return (
        <div>
          {this.state.navPageIsOpen ? this.state.navpage : <div/>}
          <div id="nav-bar">
            <Link to="/"><img alt="Dark Horse Logo" id="navLogo" className="mob" src={WhiteLogo} /></Link>
              <div id="menu-hamburger" onClick={this.openNavPage.bind(this)}>
                <div id="top-menu-hamburger" className="hamburger-line" ref={div => this.hamburgerRefs[0] = div}/>
                <div id="middle-menu-hamburger" className="hamburger-line" ref={div => this.hamburgerRefs[1] = div}/>
                <div id="bottom-menu-hamburger" className="hamburger-line" ref={div => this.hamburgerRefs[2] = div}/>
              </div>
            </div>
            <div style={{height:"15vh"}} />
          </div>
      );
    }
    else {
      return (
        <div>
          <div id="nav-bar">
            <Link to="/"><img alt="Dark Horse Logo" id="navLogo" src={WhiteLogo} /></Link>
            <div id="navLinks">
              <Link to="/" className="navLink">Home.</Link>
              {` `}
              <Link to="/services/" className="navLink">Services.</Link>
              {` `}
              <Link to="/our-work/" className="navLink">Our Work.</Link>
              {` `}
              <Link to="/about/" className="navLink">About.</Link>
              {` `}
              <Link to="/lets-talk/" className="navLink" id="contactLink">Lets Talk.</Link>
            </div>
          </div>
          <div style={{height:"15vh"}} />
        </div>
      );
    }
  }
}
